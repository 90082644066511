.MuiMenu-list {
	background-color: #000;
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
	background-color: #161f36 !important;
}

.MuiListItem-button:hover {
	background-color: lightslategrey !important;
}
